.challenges {
    & {
        padding-top: 108px;
        @include media-breakpoint-down(sm) { 
            padding: 40px 0 32px;
        }

        @include media-breakpoint-between(sm, lg) { 
            padding: 80px 0 32px;
        }
    }
    &__body {
        width: 70%;
        font-weight: 400;
        color: #495057;
    }
    &__items {
        width: 100%;
    }
    &__item {
        border: 1px #dee2e6;
    }
    &__img {
        width: 100%;

        img {
            width: 100%;
            border-radius: 12px;
            object-fit: contain;
        }
    }
    &__item-body {
        color: #495057;
    }
}
