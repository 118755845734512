.hero {
    & {
        padding: 120px 0 0 0;
        @include media-breakpoint-down(sm) { 
            padding: 40px 0 0 0;
        }

        @include media-breakpoint-between(sm, lg) { 
            padding: 80px 0 0 0;
        }
    }
    &__name {
        font-family: 'Lota-Grotesque-Regular', sans-serif !important;
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 14px !important;

        @include media-breakpoint-down(md) { 
            font-size: 20px;
        }
    }
    .primary-text {
        width: 70%;
        color: #495057;
    }
    &__description {
        font-size: 40px;
        line-height: 56px;
        font-weight: 400;
        @include media-breakpoint-up(lg) { 
            padding-right: 6.75rem;
        }
        @include media-breakpoint-down(md) { 
            font-size: 32px;
            line-height: 44px;
        }
    }
}