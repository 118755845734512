*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

* {
    padding: 0;
    margin: 0;
}

p {
    margin-bottom: 0 !important;
    font-family: 'Wotfard', sans-serif;
    display: inline-block;
}

a {
    text-decoration: none;
    font-family: 'Wotfard', sans-serif;
    color: black !important;
}
 
h1, h2, h3, h4, h5, h6 {
    font-family: 'Lota-Grotesque-Regular', sans-serif;
    color: #212529;
}

.container-fluid {
    @include media-breakpoint-down(sm) { 
        padding-left: 1rem;
        padding-right: 1rem;
    }
    @include media-breakpoint-between(md, lg) { 
        padding-left: 3rem;
        padding-right: 3rem;
    }
    @include media-breakpoint-up(lg) { 
        padding-left: 8rem;
        padding-right: 8rem;
    }
}

/* -------------------------------------------------------------------------- */
/* footer                                                                     */
/* -------------------------------------------------------------------------- */
.footer {
    & {
        padding: 108px 0 32px;
    }
}

/* -------------------------------------------------------------------------- */
/* Others                                                                     */
/* -------------------------------------------------------------------------- */
.hero-others {
    &__img{
        width: 100%;

        img {
            width: 100%;
        }
    }
}

/* -------------------------------------------------------------------------- */
/* Beesiha                                                                    */
/* -------------------------------------------------------------------------- */
.beesiha {
    & {
        padding-top: 108px;
        padding-left: 7rem;
        padding-right: 7rem;
        @include media-breakpoint-down(sm) { 
            padding-left: 0;
            padding-right: 0;
        }
    
        @include media-breakpoint-between(sm, lg) { 
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .beesiha-logo {
        width: 120px;
        margin-bottom: 1rem;

        img {
            width: 100%;
        }
    }

    .about, .challenge, .old-design, .research, .competitive-analysis, .survey, .user-flow, .design, .prototype, .results, .next-steps, .learnings, .live-prototype {
        margin-top: 72px;
    }

    .case-study-img {
        margin-top: 40px;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            border-radius: 20px;
            width: 100%;
            height: auto;
        }
    }

    .img-title {
        margin-top: 12px;
        color: #69747E;
    }
    .tertiary-title {
        margin-bottom: 20px;
    }
    .user-testing, .user-interviews, .profiles, .profile-clinic, .appointment-booking, .notifications, .user-not-logged {
        margin-top: 52px;

        h4 {
            font-size: 26px;
            margin-bottom: 20px;
        }
    }
}

.overview {
    &__litte-title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 16px;
    }
}

.factory-digitale {
    .quote {
        & {
            margin-top: 40px;
            width: 100%;
            padding: 4rem 3rem;
            background-color: #f7f7f7;
            border-radius: 20px;
        }
        p {
            font-size: 40px;
            line-height: 52px;
            font-family: 'Lota-Grotesque-Regular', sans-serif;
        }
    } 
}

