/* -------------------------------------------------------------------------- */
/* Side Navbar                                                                */
/* -------------------------------------------------------------------------- */
@include media-breakpoint-down(md) { 
    .side-navbar {
         position: fixed;
         top: 0;
         bottom: 0;
         left: 100%; 
         z-index: 998;
         width: 100%;
         height: 100vh;
         overflow: hidden;
         visibility: hidden;
         background-color: $white;
         transition: all .4s ease-in, -webkit-transform .4s ease-in;
         transition: transform .4s ease-in, visibility .4s ease-in;
         transition: transform .4s ease-in, visibility .4s ease-in, -webkit-transform .4s ease-in;
         scrollbar-width: none;
 
         .active {
             color: $black !important;
         }
 
         &::-webkit-scrollbar {
             display: none; 
             -ms-overflow-style: none;
         }
     }
 
     .side-navbar.side-navbar--open {
         overflow: hidden;
         visibility: visible;
         -webkit-transform: translateX(100%);
         transform: translateX(100%);
         -webkit-transform: translateX(-100%);
         transform: translateX(-100%);
     }
 }

 
 
 
 