/* -------------------------------------------------------------------------- */
/* Nav Master                                                                 */
/* -------------------------------------------------------------------------- */
@import "logo";
@import "side-navbar";

.nav-master {
    & {
        height: 5.625rem; /*90*/
        width: 100%;
    }

    .logo {
        position: relative;
        z-index: 999;
        display: block;
        width: 120px;

        img {
            width: 100%;
        }
    }

    .menu {
        @include media-breakpoint-down(md) {
            padding-top: 7rem;
            width: 100%;
        }

        .nav-item {
            display: block;
            align-self: center;

            @include media-breakpoint-up(md) {
                margin-left: 2.5rem;
            }
        }

        .nav-link {
            font-size: 20px;
            padding: 0 !important;
            transition: color 0.15s;
            color: $black;

            @include media-breakpoint-down(md) {
                font-size: 38px;
                margin-bottom: 1.5rem;
            }
        }

        .active {
            color: $white;

            @include media-breakpoint-down(lg) {
                color: $black;
            }
        }

        .btn {
            margin-left: 2rem;

            @include media-breakpoint-down(sm) {
                margin-left: 0;
                width: 100%;
                margin-top: 1rem;
            }

            @include media-breakpoint-between(sm, lg) {
                margin: 1rem auto 0;
                width: 100%;
            }

            @include media-breakpoint-between(lg, xl) {
                font-size: 1rem;
                margin-left: 1rem;
            }
        }
    }

    .menu-closed {
        position: relative;
        z-index: 999;
    }
}
