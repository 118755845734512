.case-studies {
    & {
        padding: 160px 0;
        @include media-breakpoint-down(sm) { 
            padding: 40px 0;
        }

        @include media-breakpoint-between(sm, lg) { 
            padding: 80px 0;
        }
    }
}