.contact {
    & {
        padding-top: 108px;
        @include media-breakpoint-down(sm) { 
            padding: 40px 0 32px;
        }

        @include media-breakpoint-between(sm, lg) { 
            padding: 80px 0 32px;
        }
    }
    .tertiary-title {
        font-weight: 400;
        @include media-breakpoint-up(lg) { 
            width: 55%;
        }
    }
    &__list {
        margin-top: 72px;
        @include media-breakpoint-up(lg) { 
            width: 60%;
            margin-left: auto;
        }
    }
    .row:nth-child(2) {
        @include media-breakpoint-up(md) { 
            margin-top: 56px;
        }
    }
    &__content {
        @include media-breakpoint-down(md) { 
            padding-top: 32px;
        }
    }
    &__content-desc {
        font-size: 22px;
        font-weight: 400;
    }
    &__social-desc {
        font-size: 20px;
        font-weight: 400;
    }
    &__email, &__phone, &__location, &__social {
        font-family: 'Lota-Grotesque-Regular', sans-serif;
        font-size: 28px;
        margin-bottom: 14px !important;
    }
    &__email, &__phone {
        position: relative;
        cursor: pointer;
    }
    &__email::before, &__phone::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        left: 0;
        bottom: -5px;
        background-color: #D0D0D0;
    }
    &__email::after, &__phone::after {
        position: absolute;
        content: "";
        width: 0;
        height: 2px;
        left: 0;
        bottom: -5px;
        background-color: black;
        transition: .4s;
    }
    &__email:hover::after, &__phone:hover::after {
        width: 100%;
    }
    &__social-link {
        position: relative;
    }
    &__social-link::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 2px;
        left: 0;
        bottom: -5px;
        background-color: #D0D0D0;
    }
    &__social-link::after {
        position: absolute;
        content: "";
        width: 0;
        height: 2px;
        left: 0;
        bottom: -5px;
        background-color: black;
        transition: .4s;
    }
    &__social-link:hover::after {
        width: 100%;
    }
}