.work {
    & {
        padding-top: 108px;
        @include media-breakpoint-down(sm) { 
            padding-top: 40px;
        }

        @include media-breakpoint-between(sm, lg) { 
            padding-top: 80px 0;
        }
    }
    &__item {
        position: relative;
    }
    &__img {
        width: 100%;
        overflow: hidden;

        img {
            width: 100%;
            object-fit: contain;
            border-radius: 20px;
        }
    }
    .margin-top {
        margin-top: 5rem;
    }
}

.project {
    position: absolute;
    bottom: 40px;
    left: 40px;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 400px;
    padding: 24px;
    background-color: white;
    border-radius: 12px;
    color: #212529;

    &__client {
        font-size: 18px;
        font-family: 'Lota-Grotesque-Regular', sans-serif;
        font-weight: 600;
        margin-bottom: .8rem;
        color: #343A40;
    }
    &__title {
        font-size: 24px;
        line-height: 28px;
        color: #212529;
        margin-top: .5rem;
    }
    &__desc {
        font-size: 18px;
        margin-top: .7rem;
    }
    &__button {
        background-color: #1F1D22;
        color: white !important;
        padding: 8px 20px;
        border-radius: 50px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        width: fit-content;
        margin-top: 1rem;
    }
}
