.menu-closed {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.875rem;
    height: 1.875rem;
    border-style: none;
    transition: all .3s ease-in, -webkit-transform .3s ease-in;

    @include media-breakpoint-up(md) { 
       display: none;
    }

    &:focus,
    &:active {
        box-shadow: none;
    }

    &__line {
        position: absolute;
        width: 1.875rem; /*30px*/
        height: 3px;
        background-color: $black;
        border-radius: .3125rem;
        transition: .5s;
        border-style: none;

        @include media-breakpoint-between(md, lg) { 
            width: 1.875rem; /*30px*/
        }

        &:nth-child(1) {
            transform: translateY(-0.4375rem);
        }
    
        &:nth-child(2) {
          transform: translateY(0.4375rem);
        }
    }
}

.menu-closed--active  {
    .menu-closed__line:nth-child(1) {
        background-color: $black;
        transform: translateY(0px) rotate(-315deg);
        transition-delay: 0.125s;
    }

    .menu-closed__line:nth-child(2) {
        background-color: $black;
        transform: translateY(0px) rotate(315deg);
        transition-delay: 0.25s;
    }
}

