@font-face {
    font-family: 'Lota-Grotesque-Regular';
    src: url('./../fonts/Lota-Grotesque-Regular.eot');
    src: url('./../fonts/Lota-Grotesque-Regular.eot?#iefix') format('embedded-opentype'),
         url('./../fonts/Lota-Grotesque-Regular.otf') format('otf'),
         url('./../fonts/Lota-Grotesque-Regular.svg#Lota-Grotesque-Regular') format('svg'),
         url('./../fonts/Lota-Grotesque-Regular.ttf') format('truetype'),
         url('./../fonts/Lota-Grotesque-Regular.woff') format('woff'),
         url('./../fonts/Lota-Grotesque-Regular.woff2') format('woff2');

         font-weight: 400;
         font-style: normal;
}

@font-face {
    font-family: 'Wotfard';
    src: url('./../fonts/Wotfard-Regular.eot');
    src: url('./../fonts/Wotfard-Regular.eot?#iefix') format('embedded-opentype'),
         url('./../fonts/Wotfard-Regular.otf') format('otf'),
         url('./../fonts/Wotfard-Regular.svg#Wotfard-Regular') format('svg'),
         url('./../fonts/Wotfard-Regular.ttf') format('truetype'),
         url('./../fonts/Wotfard-Regular.woff') format('woff'),
         url('./../fonts/Wotfard-Regular.woff2') format('woff2');
    
         font-weight: 400;
         font-style: normal;
}
  
  