.primary-title {
    font-size: 64px;
    line-height: 72px;
    font-weight: 500;
    letter-spacing: -2px;

    @include media-breakpoint-down(md) { 
        font-size: 40px;
        line-height: 60px;
    }
}

.secondary-title {
    font-size: 56px;
    line-height: 64px;
    font-weight: 500;
}

.tertiary-title {
    font-size: 40px;
    line-height: 56px;
    font-weight: 600;
}

.primary-text {
    font-size: 24px;
}

.secondary-text {
    font-size: 20px;
    line-height: 28px;
    color: #343A40;
}

.tertiary-text {
    font-size: 18px;
    line-height: 26px;
    color: #343A40;
}