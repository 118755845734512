.services {
    & {
        padding: 160px 0;
        @include media-breakpoint-down(sm) { 
            padding: 40px 0;
        }

        @include media-breakpoint-between(sm, lg) { 
            padding: 80px 0;
        }
    } 
    &__content {
        padding-top: 72px;
        @include media-breakpoint-up(lg) { 
            width: 60%;
            margin-left: auto;
        }
    }
    &__dev {
        @include media-breakpoint-down(md) { 
            margin-top: 32px;
        }
    }
    &__title {
        font-size: 28px;
        font-family: 'Lota-Grotesque-Regular', sans-serif;
        font-weight: 600;
        margin-bottom: 16px !important;
    }
    &__desc {
        font-size: 20px;
        line-height: 36px;
        font-weight: 400;
    }
}